import React from "react";

const Logo = ({ className }) => {
	return (
		<div className={`text-indianred font-extrabold text-3xl m-2 ${className}`}>
			LEMDAS
		</div>
	);
};

export default Logo;
